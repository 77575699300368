import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./fonts/fonts.css";
import VueSocketIO from "vue-socket.io";
import axios from "axios";

Vue.prototype.$axios = axios;
Vue.use(
  new VueSocketIO({
    // 生产环境建议关闭，开发环境打开(在控制台看到socket连接和事件监听的信息)
    debug: true,
    connection: "https://daping.khwlkj.top:2120",
    options: {
      // 创建时是否自动连接 我们这里设定为false,在指定页面再开启
      autoConnect: false,
      // 路径(默认值：/socket.io/)
      path: "/my-app/",
      // 目前有两种传输方式：HTTP long-polling（可简称：polling）、WebSocket
      transports: ["polling"],
      // 附加请求头（在服务器端的 socket.handshake.headers 对象中找到）
      extraHeaders: {},
    },
    // 如果没有使用到 store 可不写
    // vuex: {
    //   store,
    //   actionPrefix: "SOCKET_", // vuex action 前缀
    //   mutationPrefix: "SOCKET_", // vuex mutation 前缀
    // },
  })
);

Vue.config.productionTip = false;

// 屏蔽错误信息
Vue.config.errorHandler = (err) => {
  console.log(err);
};
// 屏蔽警告信息
Vue.config.warnHandler = (message) => {
  console.log(message);
};

Vue.prototype.$bus = new Vue();

import { detectZoom } from "@/utils/detectZoom";
// 处理笔记本系统默认系统比例为125%或150%带来的布局影响
const m = detectZoom();
document.body.style.zoom = 100 / Number(m);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
