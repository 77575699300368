// api.js
import axios from "axios";

const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_URL,
  // baseURL:
  //   "https://www.fastmock.site/mock/117ce92e7f26f2a6c2408191b8443f45/simulation",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
    Authorization: JSON.parse(localStorage.getItem("token")),
  },
});

instance.interceptors.request.use(
  (config) => {
    // 在发送请求之前做些什么
    return config;
  },
  (error) => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么
    return response.data;
  },
  (error) => {
    // 对响应错误做些什么
    return Promise.reject(error);
  }
);

export const get = (url, params, responseType = "") => {
  return instance.get(url, { params }, responseType);
};

export const post = (url, data, headers) => {
  return instance.post(url, data, headers);
};

export const DELETE = (url, data) => {
  return instance.delete(url, data);
};
export const put = (url, data) => {
  return instance.put(url, data);
};

// 请求拦截器
// axios.interceptors.request.use((config) => {
//   // 登录拦截、权限校验
//   // 添加token【令牌、唯一标识】
//   // 自动把token这个参数添加到请求头中
//   // 判断你请求的接口是不是登录和注册
//   config.headers["Authorization"] = localStorage.getItem("token");

//   return config;
// });

// // 响应拦截器
// axios.interceptors.response.use((config) => {
//   return config;
// });
