// 以下为 PC 端运行的示例，移动端暂不演示代码了，原理都一样仅是布局不同而已！ //
更多参数细节，详见文档！ // 注意：必须给 vue-danmaku 组件 `style`
设置宽高才能正常使用！

<template>
  <div>
    <!-- 弹幕列表 -->
    <section class="pop">
      <vue-danmaku
        v-model="list"
        :speeds="config.speeds"
        :randomChannel="config.randomChannel"
        :isSuspend="config.isSuspend"
        :loop="config.loop"
        :useSlot="config.useSlot"
        :channels="config.channels"
        :top="config.top"
        :right="config.right"
        :debounce="config.debounce"
        ref="Danmaku"
        style="height: 600px; width: 100%"
      >
        <template slot="dm" slot-scope="{ index, danmu }">
          <!-- <span>当前索引: {{ index }}</span> -->
          <section class="content" @click="action(danmu, index)">
            <!-- 头像 -->
            <img :src="danmu.avatar" class="avarts" />
            <!-- 如果是自己的弹幕,就标红加粗字体 -->
            <div
              class="text"
              :style="danmu.flag == 'my' ? 'font-weight: bold;color:red' : ''"
            >
              {{ danmu.msg }}
              <img class="hj" :src="danmu.gift" />
            </div>
          </section>
        </template>
      </vue-danmaku>
    </section>
    <!-- END -->

    <!-- 操作按钮 -->
    <!-- <section> -->
    <!-- <button @click="getList()" class="btn">获取数据</button> -->
    <!-- <button @click="$refs.Danmaku.show()" class="btn">显示弹幕</button>
      <button @click="$refs.Danmaku.hide()" class="btn">隐藏弹幕</button>
      <button @click="$refs.Danmaku.pause()" class="btn">暂停播放</button>
      <button @click="$refs.Danmaku.play()" class="btn">继续播放</button> -->
    <!-- </section> -->
    <!-- END -->

    <!-- 发送弹幕 -->
    <!-- <section>
      <span>自己发送弹幕（标红加粗）：</span>
      <input value="这是我自己发的弹幕" />
      <button @click="sendPush('这是我自己发的弹幕')">确认</button>
    </section> -->
    <!-- END -->

    <!-- 配置描述 -->
    <!-- <section>
      <h3>更多配置，详见下方文档</h3>
    </section> -->
    <!-- END -->
  </div>
</template>

<script>
// 引入组件
import vueDanmaku from "vue-danmaku";
import io from "socket.io-client";

export default {
  components: { vueDanmaku },
  data() {
    return {
      // 弹幕列表(图片资源可能随时失效，如失效请自行更换)
      list: [],

      // 弹幕配置
      config: {
        speeds: 120, //弹幕速度
        randomChannel: true, //随机选择轨道插入
        isSuspend: true, //是否开启弹幕悬停(PC是鼠标悬停，移动端是点击悬停)
        loop: false, //是否开启循环播放
        useSlot: true, //是否开启弹幕插槽(自定义样式时开启)
        channels: 6, //轨道数量
        top: 15, //弹幕垂直间距(px)
        right: 15, //弹幕水平间距(px)
        debounce: 1,
        // ...
        // 更多参数详见文章底部
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    /**
     * 获取数据
     * @description 因为list是双向绑定,所以赋值后会自动开启弹幕
     * @return void
     */
    getList() {
      var that = this;
      // 换成请求即可
      that.$socket.open();
      that.sockets.subscribe("new_msg", (res) => {
        console.log(res);
        if (res.type == 1) {
          that.list.push(res.msg);
          console.log(that.list, "?????????");
        }
      });
    },

    /**
     * 点击发送弹幕
     * @description 插入到节点中
     * @param {String} text - 弹幕内容
     * @return void
     */
    sendPush(text) {
      // 注意：不适用于频繁插入，详情请看文档
      this.$refs.Danmaku.insert({
        // 图片
        img: "https://img01.yzcdn.cn/vant/cat.jpeg",
        // 插入的内容
        text: text,
        // 额外插入一个字段，用于高亮显示
        // 模板进行判断,增加额外样式
        flag: "my",
      });
    },

    /**
     * 点击弹幕
     * @description 执行操作
     * @param {Object} row - 行数据
     * @param {Number} index - 索引
     * @retrun void
     */
    action(row, index) {
      console.log(row, index);
    },

    /**
     * 监听弹幕滚动完毕
     * @description 如果开启loop循环播放,永不执行(如果关闭,则弹幕滚动完后触发)
     * @param {Number} index - 最后一个弹幕的下标
     * @return void
     */
    getEnd(index) {
      console.log("滚动结束", index);
    },
  },
};
</script>

<style>
/* 自定义弹幕样式(根据需求自行更改) */
.content {
  /* background: rgba(255, 255, 255, 0.2); */
  padding: 6px 5px 1px 5px;
  /* border-radius: 20px; */
  width: 100%;
  display: flex;
  align-items: center;
}
.avarts {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  /* background: green; */
}
.text {
  height: 80px;
  display: flex;
  /* vertical-align: top; */
  /* vertical-align: top; */
  font-size: 15px;
  margin-left: 2px;
  padding-top: 5px;
  background-image: url("https://oss.khwlkj.top/hddp/lyk.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 15rpx;
  margin-left: 15px;
  z-index: 111111;
  line-height: 80px;
  /* background: green; */
  /* background: red; */
}
.hj {
  width: 55px;
  height: 60px;
  margin-left: 10px;
}
/* END */

/* 按钮样式 */
.btn {
  width: 100px;
  height: 50px;
  margin: 10px;
}
/* END */
</style>
