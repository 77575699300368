<template>
  <div class="home" :style="{ backgroundImage: `url(${pc_image})` }">

    <div class="videoContainer" :key="videoIdx" v-if="pc_video">
      <video class="fullscreenVideo" id="bgVid" playsinline="" autoplay="" muted="" loop="">
        <source :src="pc_video" type="video/mp4">
       </video>
    </div>

    <div class="pop">
      <EchaetsCom></EchaetsCom>
    </div>
    <div>
      <div class="home-top">
        <img :src="logo" mode="scaleToFill" />
        <div class="title">{{ title }}</div>
      </div>
      <div class="home-cen">
        <div class="left">
          <div class="list" v-for="(item, index) in listarr" :key="index">
            <div v-if="index <= 2">
              <img
                class="left-pic"
                v-if="index == 0"
                src="https://oss.khwlkj.top/hddp/1.png"
              />
              <img
                class="left-pic"
                v-if="index == 1"
                src="https://oss.khwlkj.top/hddp/2.png"
                mode="scaleToFill"
              />
              <img
                class="left-pic"
                v-if="index == 2"
                src="https://oss.khwlkj.top/hddp/3.png"
                mode="scaleToFill"
              />
            </div>
            <div class="left-pic" v-else>{{ index + 1 }}</div>
            <div class="list-right">
              <img class="pic" :src="item.avatar" />
              <div class="rih">
                <span>{{ item.nickname }}</span>
                <div>
                  <img
                    class="x"
                    v-for="(img, index) in item.grade"
                    :key="index"
                    :src="img"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="con">
          <div class="cons">
            <div class="ds-pm" v-for="(item, index) in listrank" :key="index">
              <div v-if="index <= 2">
                <img
                  class="jg"
                  v-if="index == 0"
                  src="https://oss.khwlkj.top/hddp/pm-1-jg.png"
                  alt=""
                />
                <img
                  class="jg"
                  v-if="index == 1"
                  src="https://oss.khwlkj.top/hddp/pm-2-jg.png"
                  alt=""
                />
                <img
                  class="jg"
                  v-if="index == 2"
                  src="https://oss.khwlkj.top/hddp/pm-3-jg.png"
                  alt=""
                />
              </div>

              <div class="txs">
                <div v-if="index <= 2">
                  <div
                    class="yq"
                    v-if="index == 0"
                    :style="{ backgroundImage: `url(${pmbg})` }"
                  >
                    <img class="tx" :src="item.avatar" mode="scaleToFill" />
                  </div>
                  <div class="yqs" v-if="index == 1">
                    <img class="tx" :src="item.avatar" mode="scaleToFill" />
                  </div>
                  <div class="yqss" v-if="index == 2">
                    <img class="tx" :src="item.avatar" mode="scaleToFill" />
                  </div>
                </div>
                <div class="yq" v-else>
                  <img class="tx" :src="item.avatar" mode="scaleToFill" />
                </div>
                <div class="xm">
                  <div class="sz">{{ index + 1 }}</div>
                  <span>{{ item.nickname }}</span>
                </div>
                <div class="xx">
                  <img
                    v-for="(img, index) in item.grade"
                    :key="index"
                    class="ds-x"
                    :src="img"
                  />
                </div>
              </div>
            </div>
          </div>
          <img :src="qr_code" mode="scaleToFill" />
        </div>
        <div class="right">
          <img class="pics" :src="arr.avatar" alt="" />
          <div class="fon">
            <div v-if="arr.name">姓名：{{ arr.name }}</div>
            <div v-if="arr.height">身高：{{ arr.height }}cm</div>
            <div v-if="arr.weight">体重：{{ arr.weight }}Kg</div>
            <div v-if="arr.blood">血型：{{ arr.blood }}</div>
            <div v-if="arr.constellation">星座：{{ arr.constellation }}</div>
            <div v-if="arr.style">音乐风格：{{ arr.style }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EchaetsCom from "../components/popup";
import io from "socket.io-client";
import { introduction, list, list_rank, singer_info } from "../requst/api";
export default {
  name: "HomeView",
  components: {
    EchaetsCom,
  },
  data() {
    return {
      pc_image: "",
      pc_video:"",
      videoIdx:1,
      pmbg: "https://oss.khwlkj.top/hddp/pm-1-y.png",
      socket: null,
      arr: {},
      listarr: [],
      listrank: [],
      qr_code: "",
      logo: "",
      title: "",
    };
  },
  created() {
    this.connectSocket();
    this.getindex();
  },
  mounted() {
    this.getdb();
    this.getgs();
    this.getbd();
  },
  methods: {
    getdb() {
      list().then((res) => {
        console.log(res);
        this.listarr = res.data;
      });
    },
    getbd() {
      list_rank().then((res) => {
        console.log(res);
        this.listrank = res.data;
      });
    },
    getgs() {
      singer_info().then((res) => {
        this.arr = res.data;
      });
    },
    connectSocket() {
      this.$socket.open();
      // 订阅事件，testCall 是与后端约定好的名称
      this.sockets.subscribe("new_msg", (res) => {
        console.log(res);
        if (res.type == 2) {
          this.arr = res.singer;
        } else {
          this.listarr = res.list;
          this.listrank = res.list_rank;
        }
      });
    },
    getindex() {
      introduction().then((res) => {
        console.log(res);
        this.pc_image = res.data.pc_image;
        this.pc_video = res.data.pc_video;
        this.qr_code = res.data.qr_code;
        this.logo = res.data.logo;
        this.title = res.data.title;
        this.videoIdx++
      });
    },
  },
  beforeDestroy() {
    // 组件销毁前断开Socket.io连接
    if (this.socket) {
      this.socket.disconnect();
    }
  },
};
</script>
<style lang="scss">
.home {
  width: 100%;
  height: 100rem;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.home-top {
  width: 100%;
  height: 120px;
  text-align: center;
  position: relative;
  font-size: 50px;
  color: #ffffff;
  font-family: "btss";
  line-height: 120px;
  letter-spacing: 5px;
  text-shadow: 0px 6px 2px rgba(0, 13, 126, 0.75);
  img {
    width: 110px;
    height: 110px;
    position: absolute;
    left: 20px;
    top: 0;
  }
}
.home-cen {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.left,
.right {
  width: 430px;
  height: 936px;
  background-image: url("https://oss.khwlkj.top/hddp/dsbd.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 30px 20px 0;
  box-sizing: border-box;
  overflow: hidden;
}
.list {
  width: 100%;
  height: 80px;
  margin-top: 19px;
  border: 1.5px solid #dec623;
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 24px;
  color: #ffffff;
  display: flex;
  .left-pic {
    width: 50px;
    height: 50px;
    margin-left: 15px;
    margin-right: 20px;
    text-align: center;
    line-height: 50px;
  }
}
.list-right {
  width: 80%;
  display: flex;
  font-size: 24px;
  color: #ffffff;
  .pic {
    width: 55px;
    height: 55px;
    border-radius: 50%;
  }
  .x {
    width: 25px;
    height: 25px;
  }
  .rih {
    margin-left: 15px;
    span {
      width: 140px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.right {
  background-image: url("https://oss.khwlkj.top/hddp/gsxx.png");
}
.con {
  width: 926px;
  text-align: center;

  .cons {
    width: 100%;
    height: 700px;
    background-image: url("https://oss.khwlkj.top/hddp/dspm.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 135px 130px 40px;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    .ds-pm {
      width: 133px;
      height: 198px;
      text-align: center;
      position: relative;
      margin-right: 40px;
      margin-top: 15px;
      .jg {
        width: 57px;
        height: 57px;
        z-index: 111;
        margin: 0;
        position: absolute;
        top: -40px;
        left: 39px;
      }
      .txs {
        width: 100%;
        text-align: center;
      }
      .yq {
        width: 104px;
        height: 106px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin: auto;
        .tx {
          width: 98%;
          height: 98%;
          border-radius: 50%;
          margin: 0;
        }
      }

      .yqs {
        width: 104px;
        height: 106px;
        background-size: 100%;
        background-image: url("https://oss.khwlkj.top/hddp/pm-2-y.png");
        background-repeat: no-repeat;
        margin: auto;
        .tx {
          width: 98%;
          height: 98%;
          border-radius: 50%;
          margin: 0;
        }
      }
      .yqss {
        width: 104px;
        height: 106px;
        background-size: 100%;
        background-image: url("https://oss.khwlkj.top/hddp/pm-3-y.png");
        background-repeat: no-repeat;
        margin: auto;
        .tx {
          width: 98%;
          height: 98%;
          border-radius: 50%;
          margin: 0;
        }
      }
      .xm {
        width: 100%;
        text-align: center;
        font-weight: 500;
        font-size: 24px;
        color: #ffffff;
        display: flex;
        margin-top: 15px;
        align-items: center;
        .sz {
          width: 23px;
          height: 23px;
          border-radius: 50%;
          text-align: center;
          line-height: 23px;
          background-color: #676590;
          font-size: 23px;
          color: #ffffff;
          margin-right: 8px;
        }
        span {
          width: 103rpx;
          display: block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .xx {
        width: 100%;
        height: 30px;
        border: 1px solid #f7df1e;
        border-radius: 15px;
        margin-top: 15px;
        text-align: left;
        padding: 3px 10px;
        box-sizing: border-box;
        display: flex;
        .ds-x {
          width: 25px;
          height: 23px;
          margin: 0 5px 0 0;
        }
      }
    }
    .ds-pm:nth-child(4),
    .ds-pm:nth-child(8) {
      margin-right: 0;
    }
    .ds-pm:nth-child(5),
    .ds-pm:nth-child(6),
    .ds-pm:nth-child(7),
    .ds-pm:nth-child(8) {
      margin-top: 0 !important;
    }
  }

  img {
    width: 175px;
    height: 175px;
    margin: 25px auto;
  }
}
.right {
  text-align: center;
  .pics {
    width: 336px;
    height: 336px;
    margin-top: 20px;
  }
  .fon {
    font-size: 24px;
    color: #ffffff;
    text-align: left;
    margin-left: 40px;
    div {
      margin-top: 20px;
    }
  }
}
.pop {
  position: absolute;
  top: 20px;
  width: 100%;
  z-index: 111111;
}


.videoContainer{
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -100;
}
.videoContainer video{
  width: 100%;
  height: 100%;
}
.videoContainer:before{
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  z-index: -1;
  top: 0;
  left: 0;
  background: rgba(25,29,34,.65);
}

</style>
